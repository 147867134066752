import Vue from "vue";
import Vuex from "vuex";
// import Common from "@/lib/common";
/*
import SecureLS from "secure-ls";
import createPersistedState from "vuex-persistedstate";
*/
/*
var lib = new SecureLS({
  encodingType: "aes",
  isCompression: false,
  encryptionSecret: "aviso27534703",
});
*/

Vue.use(Vuex);

/*
const sessionStoragePlugin = createPersistedState({
  key: "lele",
  storage: {
    getItem: (key) => ls.get(key),
    setItem: (key, value) => ls.set(key, value),
    removeItem: (key) => ls.remove(key),
    // getItem: (key) => window.sessionStorage.getItem(key),
    // setItem: (key, value) => window.sessionStorage.setItem(key, value),
    // removeItem: (key) => window.sessionStorage.removeItem(key),
  },
});
*/

// const localStorePlugin = (store) => {
//   // 當 store 初始化後調用
//   store.subscribe((mutation, state) => {
//     // 每次 mutation 之後調用
//     // mutation 的格式為 { type, payload }
//     console.log("[STORE] mutation :", mutation);
//     console.log("[STORE] state :", state);
//   });
// };

export default new Vuex.Store({
  // plugins: [sessionStoragePlugin],
  state: {
    drawerStatus: false,
    userData: null,
    selectCommunity: null,
    themeColor: "#33a6b8",
    themeColor_light: "#81c7d4",
    themeColor_light2: "#9dcfd9",
    themeColor_light3: "#257885",

    themeColor_light_new: "#91BFC4",
    themeColor_light2_new: "#F2F2F2",

    windowWidth: 1024,
    windowHeight: 768,
    isMobile: false,
    // isAdmin: false,
    jwttoken: null,
    permissions: [],
    // TODO 主畫面項目列表(暫存)
    mainMenuItems: null,
    // TODO 主畫面項目排序(k:title、v:index)(暫存)
    mainMenuIndexMap: null,
    // TODO 主畫面儀表板顯示項目列表(暫存)
    mainMenuShowPanels: [
      { key: "Guest", title: "目前訪客", path: { name: "GuestLeave" } },
      { key: "Receive", title: "未領包裹", path: { name: "PostalList" } },
      {
        key: "Return",
        title: "未退物品",
        path: {
          name: "PostalList",
          params: {
            Return: true,
          },
        },
      },
      { key: "Deposit", title: "未領寄物", path: { name: "DepositList" } },
    ],
    // TODO 主畫面儀表板隱藏項目列表(暫存)
    mainMenuHidePanels: [
      { key: "Facility", title: "公設未離場", path: { name: "FacilityList" } },
      { key: "Feedback", title: "意見未回覆", path: { name: "FeedbackList" } },
      { key: "Activity", title: "本日活動", path: { name: "CalendarView" } },
      { key: "Gas", title: "抄表未登記", path: { name: "GasList" } },
      { key: "MgmtFee", title: "管理費未繳", path: { name: "MgmtFeeList" } },
    ],
    // TODO 推播類型功能項目列表(暫存)
    notificationTypes: [],
    // TODO 用戶詳細顯示項目列表(暫存)
    houseHoldShowItems: [
      { text: "行動電話", value: "Phone", align: "center", width: "10%" },
      { text: "室內電話", value: "Tel", align: "center", width: "10%" },
      { text: "緊急聯絡人", value: "EmergencyContact", align: "center", width: "10%" },
      { text: "緊急聯絡人電話", value: "EmergencyPhone", align: "center", width: "10%" },
      { text: "汽車車牌", value: "CarPlate", align: "center", width: "10%" },
      { text: "機車車牌", value: "MotorcyclePlate", align: "center", width: "10%" },
      { text: "門禁卡號", value: "DoorCard", align: "center", width: "10%" },
    ],
    // TODO 用戶詳細隱藏項目列表(暫存)
    houseHoldHideItems: [
      { text: "備註", value: "PS", align: "center", width: "10%" },
      { text: "職業", value: "Profession", align: "center", width: "10%" },
      { text: "車位", value: "ParkingSpace", align: "center", width: "10%" },
      { text: "謂稱", value: "appellation", align: "center", width: "10%" },
      { text: "年齡", value: "Age", align: "center", width: "10%" },
    ],
  },
  getters: {
    getThemeColor(state) {
      return state.themeColor;
    },
    getThemeColorLight(state) {
      return state.themeColor_light;
    },
    getThemeColorLight2(state) {
      return state.themeColor_light2;
    },
    getThemeColorLight3(state) {
      return state.themeColor_light3;
    },
    getThemeColorLigh_new(state) {
      return state.themeColor_light_new;
    },
    getThemeColorLight2_new(state) {
      return state.themeColor_light2_new;
    },
    getWindowWidth(state) {
      return state.windowWidth;
    },
    getWindowHeight(state) {
      return state.windowHeight;
    },
    getToken(state) {
      // return ls.get("jt");
      return state.jwttoken;
    },
    getSelectCommunity(state) {
      return state.selectCommunity;
    },
    // getCommunityName(state) {
    //   return state.communityName;
    // },
    getUser(state) {
      return state.userData;
    },
    isMobile(state) {
      return state.isMobile;
    },
    isDrawerShow(state) {
      return state.drawerStatus;
    },
    // hasModule(state, moduleName) {
    //   if (!moduleName) return true;

    //   for (let mi = 0; mi < state.selectCommunity.Module.length; mi++) {
    //     if (state.selectCommunity.Module[mi].Name == moduleName) {
    //       return true;
    //     }
    //   }
    //   return false;
    // },
    /** 獲取主畫面項目排列索引 */
    getMainMenuIndexMap(state) {
      return state.mainMenuIndexMap ?? JSON.parse(window.localStorage.getItem("mainMenuIndexMap"));
    },
    /** 獲取主畫面儀表板項目 */
    getMainMenuPanels(state) {
      const panels = JSON.parse(window.localStorage.getItem("mainMenuPanels"));
      return state.mainMenuShowPanels && panels ? panels : { show: state.mainMenuShowPanels, hide: state.mainMenuHidePanels };
    },
    /** 獲取住戶資料欄位 */
    getHouseHoldItems(state) {
      const items = JSON.parse(window.localStorage.getItem("houseHoldItems"));
      return state.houseHoldShowItems && items ? items : { show: state.houseHoldShowItems, hide: state.houseHoldHideItems };
    },
  },
  mutations: {
    login(state, token) {
      state.jwttoken = token;
      window.sessionStorage.setItem("jwttoken", token);
    },
    setUser(state, user) {
      state.userData = user;
      window.sessionStorage.setItem("userData", JSON.stringify(user));
    },
    setDrawerShow(state, isShow) {
      state.drawerStatus = isShow;
    },
    setWindowResolution(state, { width, height }) {
      state.windowWidth = width;
      state.windowHeight = height;
      state.isMobile = width < 769;
    },
    setSelectCommunity(state, community) {
      state.selectCommunity = community;
      window.sessionStorage.setItem("selectCommunity", JSON.stringify(community));
    },
    logout(state) {
      state.jwttoken = null;
      state.menuStatus = null;
      state.userData = null;
      window.sessionStorage.removeItem("jwttoken");
      window.sessionStorage.removeItem("selectCommunity");
      window.sessionStorage.removeItem("userData");
    },

    // ============== 測試方法 ==============
    /** 暫存當前主畫面項目資料 */
    setMainMenuItems(state, items) {
      state.mainMenuItems = items;
    },
    /** 設置主畫面項目排列索引 */
    setMainMenuIndexMap(state, items) {
      state.mainMenuIndexMap = items;
      window.localStorage.setItem("mainMenuIndexMap", JSON.stringify(items));
    },
    /** 設置主畫面儀表板項目 */
    setMainMenuPanels(state, { show, hide }) {
      state.mainMenuShowPanels = show;
      state.mainMenuHidePanels = hide;
      window.localStorage.setItem("mainMenuPanels", JSON.stringify({ show, hide }));
    },
    /** 設置住戶資料欄位 */
    setHouseHoldItems(state, { show, hide }) {
      state.houseHoldShowItems = show;
      state.houseHoldHideItems = hide;
      window.localStorage.setItem("houseHoldItems", JSON.stringify({ show, hide }));
    },
    setNotificationTypes(state, types) {
      state.notificationTypes = types;
    },
    addNotificationType(state, type) {
      state.notificationTypes.push(type);
    },
    deleteNotificationType(state, type) {
      const arr = state.notificationTypes.filter((item) => item.Name !== type.Name);
      state.notificationTypes = arr;
    },
    // =====================================
  },
  actions: {},
  modules: {},
});

// Subscribe to store updates
// console.log(Vuex);
// Vuex.subscribe((mutation, state) => {
//   // Store the state object as a JSON string
//   // localStorage.setItem('store', JSON.stringify(state));
//   console.log("[STORE] mutation :", mutation);
//   console.log("[STORE] state :", state);
// });
