// 繁體中文 語系檔案
export default {
  appFooter2: "2023 © HappyLink 樂樂",
  appName: "樂樂",
  // copyright: "2023 © SCSL 智捷星鏈",
  // copyright: "© 2023 翊峰科技股份有限公司 版權所有",
  copyright: "© 2023 鼎生智能股份有限公司 版權所有",
  slogan: "社區最聰明的夥伴",
  modify: "編輯",
  delete: "刪除",
  search: "搜尋",
  contractTime: "合約到期日",
  cancel: "取消",
  save: "儲存",
  networkError: "網路連線異常",
  login: {
    loginSuccess: "登入成功",
    loginError: "登入失敗",
    loginAccessDenied: "權限不足",
    loginSNPWError: "帳號或密碼錯誤", //"Username or password incorrect";
    accountIDError: "帳號不存在",
    passwordError: "密碼錯誤",
  },
  carousel: {
    prev: "上一頁",
    next: "下一頁",
    ariaLabel: {
      delimiter: "Carousel slide {0} of {1}",
    },
  },
  dataIterator: {
    noResultsText: "沒有符合條件的結果",
    loadingText: "讀取中...",
  },
  dataTable: {
    itemsPerPageText: "每頁列數：",
    ariaLabel: {
      sortDescending: "：降序排列。",
      sortAscending: "：升序排列。",
      sortNone: "無排序方式。點擊以升序排列。",
      activateNone: "點擊以移除排序方式。",
      activateDescending: "點擊以降序排列。",
      activateAscending: "點擊以移除排序方式。",
    },
    sortBy: "排序方式",
  },
  dataFooter: {
    itemsPerPageText: "每頁項目：",
    itemsPerPageAll: "全部",
    nextPage: "下一頁",
    prevPage: "上一頁",
    firstPage: "第一頁",
    lastPage: "最後頁",
    pageText: "{2} 條中的 {0}~{1} 條",
  },
  datePicker: {
    itemsSelected: "已選擇 {0}",
    nextMonthAriaLabel: "下個月",
    nextYearAriaLabel: "明年",
    prevMonthAriaLabel: "前一個月",
    prevYearAriaLabel: "前一年",
  },
  noDataText: "沒有資料",
  pagination: {
    ariaLabel: {
      wrapper: "分頁導航",
      next: "下一頁",
      previous: "上一頁",
      page: "轉到頁面 {0}",
      currentPage: "當前頁 {0}",
    },
  },
  form: {
    rule: {
      required: "必要",
      invalidEmail: "E-mail 格式錯誤",
      number: "必須為數字",
      minIsZero: "最小為零",
      mustGreatedZero: "必須大於零",
      startTimeMustLessEndTime: "開始時間必須小於結束時間",
      endTimeMustGreateStartTime: "結束時間必須大於開始時間",
      maxRange7D: "最大區間為七日",
      maxRange1M: "最大區間為一個月",
    },
  },
  calendar: {
    moreEvents: "還有 {0} 個",
  },
};
